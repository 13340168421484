<template>
  <v-row no-gutters>
    <v-col v-if="fecha" :cols="hora ? 7 : 12">
      <!-- FECHA -->
        <!-- :rules="[validacionCampo]" -->
      <v-text-field
        dense
        :label="tituloFecha"
        v-model="fechaFormateada"
        :disabled="$attrs.disabled"
        prepend-icon="mdi-calendar"
        readonly
        :rules="[validacionCampo]"
        @click="mostrarVentanaFecha"
      ></v-text-field>
      <v-dialog
        v-model="ventanaFechaVisible"
        width="290px"
      >
        <v-date-picker
          v-if="ventanaFechaVisible"
          first-day-of-week="1"
          scrollable
          :locale="idioma"
          :readonly="$attrs.readonly"
          :min="fechaMinima"
          :allowed-dates="getAllowedDates"
          :max="fechaMaxima"
          v-model="datePicker"
          @input="inputDatePicker"
        >
          <v-btn v-if="botonBorrar" text @click="borrar" :disabled="!datePicker">Borrar</v-btn>
          <v-spacer />
          <v-btn v-if="requerirConfirmacion" text @click="aceptarFecha" :disabled="!datePicker">Aceptar</v-btn>
          <v-btn text @click="ventanaFechaVisible = false">Cancelar</v-btn>
        </v-date-picker>
      </v-dialog>
    </v-col>
    <v-col v-if="hora" cols="5">
      <!-- HORA -->
      <v-text-field
        dense
        :label="tituloHora"
        v-model="horaFormateada"
        prepend-icon="mdi-clock-outline"
        readonly
        :rules="[validacionCampo]"
        @click="mostrarVentanaHora"
      ></v-text-field>
      <v-dialog
        v-model="ventanaHoraVisible"
        width="290px"
      >
        <v-time-picker
          v-if="ventanaHoraVisible"
          format="24hr"
          scrollable
          :readonly="$attrs.readonly"
          v-model="timePicker"
          @click:minute="clickMinuteTimePicker"
        >
          <v-btn v-if="botonBorrar" text @click="borrar" :disabled="!datePicker">Borrar</v-btn>
          <v-spacer />
          <v-btn v-if="requerirConfirmacion" text @click="aceptarHora" :disabled="!timePicker">Aceptar</v-btn>
          <v-btn text @click="ventanaHoraVisible = false">Cancelar</v-btn>
        </v-time-picker>
      </v-dialog>
    </v-col>
  </v-row>
</template>

<script>
import { DateTime } from "luxon";

export default {
  props: {
    fechaMaxima:{
      type: String
    },
    fechasNoPermitidas:{
      type:Array
    },
    value: {
      required: true,
    },
    fechaMinima:{
      type: String
    },
    formatoModelo: {
      type: String,
      default: "sql",
    },
    idioma:{
      type: String,
      default: "es"
    },
    fecha: {
      type: Boolean,
      default: true,
    },
    tituloFecha: {
      type: String,
    },
    formatoFecha: {
      type: String,
      default: "D",
    },
    hora: {
      type: Boolean,
      default: true,
    },
    tituloHora: {
      type: String,
    },
    formatoHora: {
      type: String,
      default: "T",
    },
    obligatorio: {
      type: Boolean,
      default: false,
    },
    literalNull: {
      type: String,
      default: "",
    },
    requerirConfirmacion: {
      type: Boolean,
      default: false,
    },
    botonBorrar: {
      type: Boolean,
      default: false,
    },
    ajustarHoras: {
      type: Number,
      default: null,
    },
    ajustarMinutos: {
      type: Number,
      default: null,
    },
    ajustarSegundos: {
      type: Number,
      default: null,
    },
    ajustarMilisegundos: {
      type: Number,
      default: null,
    },
    horaPorDefecto: {
      type: String,
      default: null,
    }
  },
  data() {
    return {
      localValue: null,
      ventanaFechaVisible: false,
      datePicker: null,
      ventanaHoraVisible: false,
      timePicker: null
    };
  },
  watch: {
    localValue() {
      let aux = null;
      if (this.localValue) {
        if (this.formatoModelo.toUpperCase() == "SQL")
          aux = this.localValue.toFormat("yyyy-MM-dd HH:mm:ss");
        else
          aux = this.localValue.toISO({ includeOffset: false });
      }
      this.$emit("input", aux);
    },
    value() {
      this.establecerValorLocalDesdeCadenaFormateada(this.value);
      this.establecerValorPickers();
    },
  },
  computed: {
    fechaFormateada() {
      if (this.localValue)
        return this.localValue.setLocale("es").toFormat(this.formatoFecha);
      else return this.literalNull;
    },
    horaFormateada() {
      if (this.localValue)
        return this.localValue.setLocale("es").toFormat(this.formatoHora);
      else return this.literalNull;
      // return '00:00'
    }
  },
  methods: {
    getAllowedDates (val) {
          if (this.fechasNoPermitidas.indexOf(val) !== -1 && this.fechasNoPermitidas.length > 0) {
            return false
          } else {
            return true
          }
    },
    establecerValorPickers() {
      if (this.localValue) {
        this.datePicker = this.localValue.toFormat("yyyy-MM-dd");
        this.timePicker = this.localValue.toFormat("HH:mm:ss");
      } else {
        this.datePicker = null;
        this.timePicker = null;
      }
    },
    establecerValorLocalDesdeCadenaFormateada(valor) {
      if (valor) {
        let aux = null;
        if (this.formatoModelo.toUpperCase() == "SQL") {
          aux = DateTime.fromFormat(valor, "yyyy-MM-dd HH:mm:ss");
          if (aux.invalid) aux = DateTime.fromFormat(valor, "yyyy-MM-dd HH:mm");
        } else aux = DateTime.fromISO(valor);
        if (!aux.invalid)
          this.localValue = aux;
      } else {
          this.localValue = null;
      }
    },
    aplicarAjusteHorasMinutosSegundosMilisegundos() {
      let _horas = (this.ajustarHoras != undefined) ? this.ajustarHoras : this.localValue.hour;
      let _minutos = (this.ajustarMinutos != undefined) ? this.ajustarMinutos : this.localValue.minute;
      let _segundos = (this.ajustarSegundos != undefined) ? this.ajustarSegundos : this.localValue.second;
      let _milisegundos = (this.ajustarMilisegundos != undefined) ? this.ajustarMilisegundos : this.localValue.millisecond;
      this.localValue = this.localValue.set({
        Hour: _horas,
        Minute: _minutos,
        Second: _segundos,
        Millisecond: _milisegundos,
      });
    },
    borrar(){
      this.ventanaHoraVisible = false;
      this.ventanaFechaVisible = false;
      this.localValue = null;
    },
    aceptarFecha() {
      let fecha = DateTime.fromISO(this.datePicker);
      if (this.localValue == null) {
        if (this.horaPorDefecto)
          this.localValue = DateTime.fromISO(this.horaPorDefecto);
        else
          this.localValue = DateTime.local();
      }
      this.localValue = this.localValue.set({
        Year: fecha.year,
        Month: fecha.month,
        Day: fecha.day,
      });
      this.aplicarAjusteHorasMinutosSegundosMilisegundos();

      this.ventanaFechaVisible = false;
    },
    aceptarHora() {
      let hora = DateTime.fromISO(this.timePicker);

      if (this.localValue == null) this.localValue = DateTime.local();
      this.localValue = this.localValue.set({
        Hour: hora.hour,
        Minute: hora.minute,
        Second: hora.second
      });
      this.aplicarAjusteHorasMinutosSegundosMilisegundos();

      this.ventanaHoraVisible = false;
    },
    mostrarVentanaFecha() {
      if (!this.$attrs.readonly && !this.$attrs.disabled) {
        this.establecerValorPickers();
        this.ventanaFechaVisible = true;
      }
    },
    mostrarVentanaHora() {
      if (!this.$attrs.readonly && !this.$attrs.disabled) {
        this.establecerValorPickers();
        this.ventanaHoraVisible = true;
      }
    },
    inputDatePicker() {
      if (!this.requerirConfirmacion)
        this.aceptarFecha();
    },
    clickMinuteTimePicker() {
      if (!this.requerirConfirmacion)
        this.aceptarHora();
    },
    validacionCampo() {
      return (!this.obligatorio) || (this.localValue !== null);
    }
  },
  created() {
    this.establecerValorLocalDesdeCadenaFormateada(this.value);
  },
};
</script>

<style>
</style>