<template>
  <v-main >
    <v-container fill-height>
      <v-row align="center" justify="center">
        <v-card
          width="400"
          class="py-6 px-10 text-center"
          elevation="4"
          style="margin-top: -20px; min-width: 300px"
          :disabled="bloquearFormulario"
        >
          <img src="@/assets/logo.png" style="max-height: 80px" />
          <!-- <div class="text-h5" style="color: #bc8d3c">{{ $t ('Zona privada') }}</div> -->
          <div class="mt-8 text-h6" style="color: #31708f">{{ $t ('Inicio de sesión') }}</div>
          <v-text-field
            ref="inputCorreoElectronico"
            class="mt-1"
            :label="$t ('Correo electrónico')"
            prepend-inner-icon="mdi-email"
            :rules="[rules.required]"
            v-model="email"
            type="email"
            spellcheck="false"
          ></v-text-field>
          <v-text-field
            :label="$t ('Contraseña')"
            prepend-inner-icon="mdi-account-key"
            :rules="[rules.required]"
            v-model="password"
            :type="mostrarPassword ? 'text' : 'password'"
            spellcheck="false"
            :append-icon="mostrarPassword ? 'mdi-eye' : 'mdi-eye-off'"
            @click:append="mostrarPassword = !mostrarPassword"
            @keypress.enter="ingresar()"
          ></v-text-field>
          <v-btn
            class="mt-2"
            block
            large
            color="primary"
            elevation="2"
            @click="ingresar"
            :disabled="!datosRellenos"
          >{{ $t ('Ingresar') }}</v-btn>
          <div class="mt-5">
            <router-link to="/olvidoContrasena">
              <a>{{ $t ('¿Olvidó su contraseña?') }}</a>
            </router-link>
          </div>
        </v-card>

        <v-dialog v-model="cuadroEmergente.mostrar" max-width="490">
          <v-card>
            <v-card-title class="headline">{{cuadroEmergente.titulo}}</v-card-title>
            <v-card-text>{{cuadroEmergente.texto}}</v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="primary darken-1" text @click="cuadroEmergente.mostrar = false">{{ $t ('Aceptar') }}</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
    </v-container>
  </v-main>
</template>

<script>
export default {
  data: () => ({
    rules: {
      required: (value) => !!value || "Requerido",
    },
    email: "",
    password: "",
    mostrarPassword: false,
    bloquearFormulario: false,
    cuadroEmergente: {
      mostrar: false,
      titulo: "",
      texto: "",
    },
  }),
  methods: {
    completarEmail() {
      if (this.email != "" && !this.email.includes("@"))
        this.email = this.email + "@" + this.$parametros_configuracion.domcorelepordef
    },
    ingresar() {
      this.bloquearFormulario = true
      this.$store
        .dispatch("INICIAR_SESION", {
          email: this.email,
          password: this.password,
        })
        .catch((e) => {
          console.log (e)
          this.cuadroEmergente.mostrar = true
          this.cuadroEmergente.titulo = this.$t ("Error de inicio de sesión")
          this.cuadroEmergente.texto = e.response.data.error ? e.response.data.error : e
        })
        .finally(() => {
          this.bloquearFormulario = false;
        });
    },
  },
  computed: {
    datosRellenos() {
      return (this.email != "" && this.password != "")
    },
  },
  mounted() {
    this.$refs["inputCorreoElectronico"].focus()
  },
};
</script>

<style>
</style>